<template>
  <div class="follow-list">
    <follow-head :followidlist="followid"></follow-head>11

    <!--内容-->
    <div v-for="item in followid.followList" :key="item.id">
      <follow-item :fol_id="item.fol_id"></follow-item>
    </div>
  </div>
</template>

<script>
import { getUserFollow } from 'network/follow'
import FollowItem from './followchild/FollowItem'
import FollowHead from './followchild/FollowHead'

export default {
  name: 'FollowList',
  data() {
    return {
      user_id: null,
      followid: {},
      followlist: {}
    }
  },
  components: {
    FollowItem,
    FollowHead
  },
  created() {
    this.user_id = window.sessionStorage.getItem('userId')
    /*获取关注*/
    this.getUserFollow()
  },
  methods: {
    /*获取关注*/
    getUserFollow() {
      getUserFollow(this.user_id).then((res) => {
        this.followid = res.data
      })
    }
  }
}
</script>

<style scoped>
.follow-list {
  width: 100%;
}
</style>
