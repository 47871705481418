<template>
  <div>
    <div v-for="item in guidelist.guideList" :key="item.id" class="follow-item">
      <div class="follow-item-user">
        <img :src="baseUrl + item.user.head" />
        <span class="user-span-name">{{ item.user.nickname }}</span>
        <span class="user-span-time">{{ item.publishDate.substr(0, 10) }}</span>
      </div>

      <div class="follow-item-images">
        <img :src="baseUrl + item.imagepath" />
      </div>

      <div class="follow-item-font">
        <div></div>
        <div></div>
        <div>
          <i class="iconfont icon-heart"></i>
          <span>123</span>
        </div>
        <div>
          <i class="iconfont icon-xing"></i>
          <span>122</span>
        </div>
        <div>
          <i class="iconfont icon-xiaoxi1"></i>
          <span>10</span>
        </div>
      </div>

      <div class="follow-item-title">
        <span>{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserGuideList } from 'network/home'
export default {
  name: 'FollowItem',
  props: {
    fol_id: {
      type: [String, Number]
    }
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_IMG_PATH,
      guidelist: {}
    }
  },
  created() {
    this.getUserGuideList()
  },
  methods: {
    getUserGuideList() {
      getUserGuideList(this.fol_id).then((res) => {
        this.guidelist = res.data
      })
    }
  }
}
</script>

<style scoped>
.follow-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid rgba(200, 200, 200, 0.5);
}
.follow-item-user {
  display: flex;
  align-items: center;
  margin: 10px 12px;
}
.follow-item-user img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.follow-item-user .user-span-name {
  font-size: 14px;
  margin: 0 8px;
}
.follow-item-user .user-span-time {
  margin-top: 8px;
  font-size: 11px;
  color: rgb(120, 120, 120);
}

.follow-item-images img {
  width: 100%;
}
/*点赞收藏*/
.follow-item-font {
  display: flex;
  text-align: center;
  align-items: center;
  margin-left: 5px;
  padding-top: 10px;
}

.follow-item-font div {
  flex: 1;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.icon-heart {
  font-size: 25px;
  font-weight: 500;
  color: #000;
}
.icon-xing {
  font-size: 20px;
  font-weight: 500;
}
.icon-xiaoxi1 {
  font-size: 21px;
  font-weight: 500;
}
.follow-item-font i {
  margin-right: 3px;
}
.follow-item-font span {
  font-size: 15px;
}

.follow-item-title {
  padding: 10px 10px;
}
.follow-item-title span {
  font-size: 15px;
  color: rgb(50, 50, 50);
}
</style>
