<template>
  <div class="follow-head">
    <div v-for="item in followidlist.followList" :key="item.id">
      <div class="follow-head-item">
        <img @click="btnpersonal(item.user1, item.user1.id)" :src="baseURL + item.user1.head" />
        <span>{{ item.user1.nickname }}</span>
      </div>
    </div>

    <!--<user-other @btnoutother="btnoutother"  :userlist="user1" :user_id="user_id"></user-other>-->
    <component
      v-for="(item, index) in comName"
      @btnoutother="btnoutother(index)"
      class="user-other"
      id="user-other"
      :key="item.id"
      :is="item.name"
      :userlist="user1"
      :user_id="user_id"
    ></component>
  </div>
</template>

<script>
import UserOther from '../../../user/userother/UserOther'
export default {
  name: 'FollowHead',
  components: {
    UserOther
  },
  props: {
    followidlist: {
      type: Object
    }
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_IMG_PATH,
      user1: {},
      user_id: null,
      comName: []
    }
  },
  mounted() {},
  methods: {
    btnpersonal(user1, id) {
      this.user1 = user1
      this.user_id = id
      /*let userother=document.getElementById("user-other");*/
      /*userother.style.left=0+"px"*/
      this.comName.push({
        name: 'UserOther'
      })
    },

    btnoutother(index) {
      /*删除组件*/
      this.comName.splice(index, 1)
      let userother = document.getElementById('user-other')
      userother.style.left = 100 + '%'
      let noteother = document.getElementById('note-other')
      let fabsother = document.getElementById('fabs-other')
      noteother.style.display = 'none'
      fabsother.style.display = 'none'
    }
  }
}
</script>

<style scoped>
.follow-head {
  display: flex;
  padding: 14px 10px 0 10px;
  overflow-x: auto;
}
.follow-head::-webkit-scrollbar {
  display: none;
}
.follow-head-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-right: 10px;
}
.follow-head-item img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.follow-head-item span {
  margin-top: 10px;
  font-size: 12px;
}
.user-other {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 20;
  transition: all 0.5s;
}
</style>
